import React, { useState } from 'react'
import { Button, Container, Nav, Navbar } from 'react-bootstrap'
import LoginPrompt from '../../modals/LoginPrompt'
import { Link, useNavigate } from 'react-router-dom'


export default function CommonNavbar() {
  const [show, setShow] = useState(false); // To show LoginPrompt
  const navigate = useNavigate();
  const handleLogout = () => {
    sessionStorage.removeItem('authToken')
    sessionStorage.removeItem('userRole')
    navigate('/')
  }
  let authToken = sessionStorage.getItem('authToken')
  const userRole = sessionStorage.getItem('userRole')
  return (
    <>
      <Navbar expand="lg" bg='primary' data-bs-theme="dark">
        <Container>
          <Navbar.Brand href="/">Ticket Tracker</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Link to='/' style={{ textDecoration: 'none' }}><Button>Home</Button></Link>
              {userRole ? (
                <Link to={`/${userRole}`} style={{ textDecoration: 'none' }}><Button>Dashboard</Button></Link>
              ) : null}
              {/* <Link to='/organisation' style={{ textDecoration: 'none' }}><Button>Organisation Login</Button></Link> */}
              {/* <Link style={{ textDecoration: 'none' }} onClick={() => setShow(true)}><Button>Track Your Ticket</Button></Link> */}
            </Nav>
            <LoginPrompt show={show} setShow={setShow} />
     
            <Nav className='d-flex'>
              {authToken ?
                (
                  <>
                  <Button disabled>Logged in as {userRole}</Button>
                  <Nav.Link>
                    <Button className='btn btn-dark' onClick={() => handleLogout()}>Logout</Button>
                  </Nav.Link>
                  </>
                ) : (
                  <Nav.Link>
                    <Button className='btn btn-dark' onClick={() => setShow(true)}>Login as Complainant</Button>
                  </Nav.Link>
                )}
            </Nav>

          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}
