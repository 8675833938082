import React from 'react'
import { Row, Col } from 'react-bootstrap'
import emblem from '../../assets/images/emblem.png'
import cpcbLogo from '../../assets/images/cpcbLogo.png'

export default function Header() {
    return (
        <>
            <Row className='mt-3 mb-3 p-2'>
                <Col md={1}>
                    <img src={emblem} alt='emblem' style={{ height: '15vh' }} />
                </Col>
                <Col md={1}>
                    <img src={cpcbLogo} alt='cpcb logo' style={{ height: '15vh' }} />
                </Col>
                <Col md={8} style={{ marginLeft: '20px' }}>
                    <h5 style={{ marginBottom: '5px' }}>Central Pollution Control Board</h5>
                    <p>Ministry of Environment, Forest and Climate Change<br />
                        Government of India
                    </p>
                </Col>
            </Row>
        </>
    )
}
