import React, { useState, useEffect, Suspense } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useUserDetails } from '../contextapi/userDetails'
import { HandlerDataProvider } from '../contextapi/handlerContext'
import CommonNavbar from '../components/common/CommonNavbar'
import ProfileCard from '../components/cards/ProfileCard'
import HandlerBody from '../components/cards/handlers/HandlerBody'
import Loading from '../components/common/Loading'
import { getApiResponse } from '../utils/apiPromise'
import { handlerMenu } from '../assets/arrays/sideBarMenu'


const handlerDashboardApi = process.env.REACT_APP_API_HANDLER_DASHBOARD

export default function HandlerDashboard() {

  const sideMenu = handlerMenu
  const [sidebarIndex, setSidebarIndex] = useState(0)

  const [fetchHandlerApi, setFetchHandlerApi] = useState(false)
  const { setUserDetails } = useUserDetails()


  const authToken = sessionStorage.getItem('authToken')
  const navigate = useNavigate()


  useEffect(() => {
    setFetchHandlerApi(true)
  }, [])


  useEffect(() => {
    const fetchData = async () => {
      try {
        const handlerResponse = await getApiResponse(handlerDashboardApi, authToken)
        setUserDetails(handlerResponse.userDetails)
      } catch (error) {
        alert(error);
        navigate('/');
      }
    }
    if (fetchHandlerApi) {
      fetchData()
    }
  }, [authToken, fetchHandlerApi, navigate, setUserDetails])




  return (
    <>
      <HandlerDataProvider>
        <Row>
          <CommonNavbar />
        </Row>

        <Suspense fallback={<Loading />}>
          <Row>
            <Col md={2} className='text-center p-4 bg-dark text-white'>
              <ProfileCard
                setSidebarIndex={setSidebarIndex}
                sideMenu={sideMenu}
              />
            </Col>

            <Col md={10}>
              <HandlerBody sidebarIndex={sidebarIndex} />
            </Col>
          </Row>
        </Suspense>
      </HandlerDataProvider>
    </>
  )
}

