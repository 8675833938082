import React from 'react'
import { Row, Col, Modal, Button } from 'react-bootstrap'
import axios from 'axios';
import formatDate from '../utils/formatDate';
import { useNavigate } from 'react-router-dom';

const fetchScreenshotsApi = process.env.REACT_APP_API_FETCH_SCREENSHOTS

export default function ViewDeveloperTicket(props) {
    const show = props.showViewTicket;
    const setShow = props.setShowViewTicket;
    const ticketDetails = props.ticketDetails;

    const navigate = useNavigate()

    const handleClose = () => {
        setShow(false);
    };


    const openScreenshot = async (fileName) => {
        const authToken = sessionStorage.getItem('authToken')
        try {
            axios.post(fetchScreenshotsApi, {
                filename: fileName
            }, {
                headers: {
                    'Authorization': authToken,
                    'Content-Type': 'application/json'
                },
                responseType: 'blob'
            })
                .then(response => {
                    const blob = new Blob([response.data], { type: 'application/pdf' });
                    const pdfUrl = URL.createObjectURL(blob);
                    window.open(pdfUrl); // Open the PDF in a new tab
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        } catch (error) {
            alert(error)
        }
    }

    const handleTrail = () => {
        navigate('/tickettrail', { state: { ticketId: ticketDetails.ticketId } });
    }

    return (
        <>
            {ticketDetails ? (
                <>
                    <Modal size='lg' show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                Ticket ID: {ticketDetails.ticketId}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            <Row className="d-flex justify-content-between p-1">
                                <Col md={4}><strong>Complainant Name</strong></Col>
                                <Col>{ticketDetails.complainantName}</Col>
                            </Row><hr className="mt-0 mb-2" />

                            <Row className="d-flex justify-content-between p-1">
                                <Col md={4}><strong>Complainant Role</strong></Col>
                                <Col>{ticketDetails.complainantRole}</Col>
                            </Row><hr className="mt-0 mb-2" />

                            <Row className="d-flex justify-content-between p-1">
                                <Col md={4}><strong>Company Name</strong></Col>
                                <Col>{ticketDetails.companyName}</Col>
                            </Row><hr className="mt-0 mb-2" />

                            <Row className="d-flex justify-content-between p-1">
                                <Col md={4}><strong>Complainant Email ID</strong></Col>
                                <Col>{ticketDetails.complainantEmailId}</Col>
                            </Row><hr className="mt-0 mb-2" />

                            <Row className="d-flex justify-content-between p-1">
                                <Col md={4}><strong>Complainant Contact Number</strong></Col>
                                <Col>{ticketDetails.complainantPhone}</Col>
                            </Row><hr className="mt-0 mb-2" />

                            <Row className="d-flex justify-content-between p-1">
                                <Col md={4}><strong>Registered EPR Number</strong></Col>
                                <Col>{ticketDetails.complainantEpr}</Col>
                            </Row><hr className="mt-0 mb-2" />

                            <Row className="d-flex justify-content-between p-1">
                                <Col md={4}>
                                    <div><strong>Generated On</strong></div>
                                    <div>{formatDate(ticketDetails.openDate)}</div>
                                </Col>
                                <Col md={4}>
                                    <div><strong>Last Updated On</strong></div>
                                    <div>{formatDate(ticketDetails.updateDate)}</div>
                                </Col>
                                <Col md={4}>
                                    <div><strong>Closed Date</strong></div>
                                    <div>{formatDate(ticketDetails.closedDate)}</div>
                                </Col>
                            </Row><hr className="mt-0 mb-2" />

                            <Row className="d-flex justify-content-between p-1">
                                <Col md={4}><strong>Ticket Status</strong></Col>
                                <Col>{ticketDetails.ticketStatus}</Col>
                            </Row><hr className="mt-0 mb-2" />

                            <Row className="d-flex justify-content-between p-1">
                                <Col md={4}><strong>Ticket Priority</strong></Col>
                                <Col>{ticketDetails.ticketPriority}</Col>
                            </Row><hr className="mt-0 mb-2" />

                            <Row className="d-flex justify-content-between p-1">
                                <Col md={4}><strong>Issue Type</strong></Col>
                                <Col>{ticketDetails.issueCategory}</Col>
                            </Row><hr className="mt-0 mb-2" />

                            <Row className="d-flex justify-content-between p-1">
                                <Col md={4}><strong>Issue Sub-Type</strong></Col>
                                <Col>{ticketDetails.issueSubcategory}</Col>
                            </Row><hr className="mt-0 mb-2" />

                            <Row className="d-flex justify-content-between p-1">
                                <Col md={4}><strong>Issue</strong></Col>
                                <Col>{ticketDetails.issue}</Col>
                            </Row><hr className="mt-0 mb-2" />

                            <Row className="d-flex justify-content-between p-1">
                                <Col md={4}><strong>Screenshots</strong></Col>
                                <Col>
                                    {ticketDetails.screenshots ? (
                                        <Button onClick={() => openScreenshot(ticketDetails.screenshots)}>{ticketDetails.screenshots}</Button>
                                    ) : null}
                                </Col>
                            </Row><hr className="mt-0 mb-2" />

                            <Row className="d-flex justify-content-between p-1">
                                <Col md={4}><strong>Division</strong></Col>
                                <Col>{ticketDetails.ticketType}</Col>
                            </Row><hr className="mt-0 mb-2" />

                            <Row className="d-flex justify-content-between p-1">
                                <Col md={4}><strong>Remark</strong></Col>
                                <Col>{ticketDetails.remark}</Col>
                            </Row>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="warning" onClick={() => handleTrail()}>
                                View Trail
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </>
            ) : null}
        </>
    )
}
