import React, { useState, useEffect, useReducer, Suspense } from 'react'
import { Row, Table, Button, Form } from 'react-bootstrap'
import { useHandlerData } from '../../../contextapi/handlerContext'
import { getApiResponse } from '../../../utils/apiPromise';
import HandlerReviewTicket from '../../../modals/HandlerReviewTicket';
import { useNavigate } from 'react-router-dom';
import formatDate from '../../../utils/formatDate';
import ViewHandlerTicket from '../../../modals/ViewHandlerTicket';
import Loading from '../../common/Loading';
import HandlerRevertTicket from '../../../modals/HandlerRevertTicket';
import Pagination from '../../common/Pagination';

const fetchHandlerTicketsApi = process.env.REACT_APP_API_FETCH_HANDLER_TICKETS

const initialState = {
  selectedTicketId: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SELECT_TICKET':
      return { ...state, selectedTicketId: action.payload };
    default:
      return state;
  }
};

function HandlerTickets() {

  const {
    updatedChanges,
    tickets,
    setTickets,
    setTicketCounts,
    viewSummary,
    searchTerm,
    setSearchTerm,
    filter,
    setFilter } = useHandlerData();
  const [fetchTicketsApi, setFetchTicketsApi] = useState(false) // To execute API once


  const [showViewTicket, setShowViewTicket] = useState(false)
  const [showReviewedTicket, setShowReviewedTicket] = useState(false)
  const [showRevertedTicket, setShowRevertedTicket] = useState(false)


  const authToken = sessionStorage.getItem('authToken')
  const navigate = useNavigate()



  useEffect(() => {
    setFetchTicketsApi(true)
  }, [])


  useEffect(() => {
    const fetchData = async () => {
      try {
        const ticketResponse = await getApiResponse(fetchHandlerTicketsApi, authToken)
        await setTickets(ticketResponse.tickets.filter(ticket => ticket.ticketStatus !== 'OPEN'))
        await setTicketCounts(ticketResponse.ticketCounts)
      } catch (error) {
        alert(error);
        navigate('/');
      }
    }
    if (fetchTicketsApi) {
      fetchData()
    }
  }, [authToken, fetchTicketsApi, navigate, setTickets, updatedChanges])



  // Using Reducing method to view/review/revert ticket
  const [state, dispatch] = useReducer(reducer, initialState);
  const { selectedTicketId } = state

  const handleViewTicket = (ticketId) => {
    setShowViewTicket(true)
    dispatch({ type: 'SELECT_TICKET', payload: ticketId });
  };

  const handleCloseViewTicket = () => {
    setShowViewTicket(false)
    dispatch({ type: 'SELECT_TICKET', payload: null });
  };

  const handleReviewTicket = (ticketId) => {
    setShowReviewedTicket(true)
    dispatch({ type: 'SELECT_TICKET', payload: ticketId });
  };

  const handleCloseReviewTicket = () => {
    setShowReviewedTicket(false)
    dispatch({ type: 'SELECT_TICKET', payload: null });
  };

  const handleRevertedTicket = (ticketId) => {
    setShowRevertedTicket(true)
    dispatch({ type: 'SELECT_TICKET', payload: ticketId });
  };

  const handleCloseRevertedTicket = () => {
    setShowRevertedTicket(false)
    dispatch({ type: 'SELECT_TICKET', payload: null });
  };

  let selectedTicket = tickets.find(ticket => ticket.ticketId === selectedTicketId);


  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Reset pagination to first page when searching
  }


  // Function to filter tickets by search term
  const filterBySearchTerm = (tickets, searchTerm) => {
    return tickets.filter(ticket =>
      ticket.ticketId.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  // Function to filter tickets by status
  const filterByStatus = (tickets, filter) => {
    switch (filter) {
      case 'review':
        return tickets.filter(ticket => ticket.ticketStatus === 'REVIEWING');
      case 'closed':
        return tickets.filter(ticket => ticket.ticketStatus === 'CLOSED');
      case 'inProgress':
        return tickets.filter(ticket => ticket.ticketStatus === 'PROGRESS');
      case 'reverted':
        return tickets.filter(ticket => ticket.ticketStatus === 'REVERTED');
      case 'showAll':
        return tickets;
      default:
        return tickets;
    }
  };

  // Main function to filter tickets
  const filteredTickets = () => {
    if (searchTerm) {
      return filterBySearchTerm(tickets, searchTerm);
    } else {
      return filterByStatus(tickets, filter);
    }
  };



  // For Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const ticketsPerPage = 20;
  const indexOfLastTicket = currentPage * ticketsPerPage;
  const indexOfFirstTicket = indexOfLastTicket - ticketsPerPage;

  const sortedTickets = filteredTickets().sort((a, b) => new Date(b.openDate) - new Date(a.openDate));
  const currentTickets = sortedTickets.slice(indexOfFirstTicket, indexOfLastTicket);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);








  return (
    <>
      <Suspense fallback={<Loading />}>

        {viewSummary ? (
          <>

            <Row className='mt-4 p-3'>
              <Table hover>
                <thead>
                  <tr>
                    <th style={{ width: '120px' }}>Ticket ID</th>
                    <th style={{ width: '120px' }}>Status</th>
                    <th style={{ width: '140px' }}>Generated On</th>
                    <th style={{ width: '140px' }}>Issue Type</th>
                    <th style={{ width: '120px', textAlign: 'center' }}>View Ticket</th>
                    <th style={{ textAlign: 'center', width: '180px' }}>Review Ticket</th>
                  </tr>
                </thead>
                <tbody>
                  {currentTickets.slice(filter === 'reverted' ? 0 : -6).map((ticket, index) => (
                    <tr key={index}>
                      <td>{ticket.ticketId}</td>
                      <td>{ticket.ticketStatus}</td>
                      <td>{formatDate(ticket.openDate)}</td>
                      <td>{ticket.issueCategory}</td>
                      <td style={{ textAlign: 'center' }}>
                        <Button onClick={() => handleViewTicket(ticket.ticketId)}>View</Button>
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        {ticket.ticketStatus === 'PROGRESS' ? (
                          <Button variant='warning' onClick={() => handleReviewTicket(ticket.ticketId)}>Set Priority</Button>
                        ) : null}
                        {ticket.ticketStatus === 'REVIEWING' ? (
                          <Button variant='success' onClick={() => handleReviewTicket(ticket.ticketId)}>Add Remark</Button>
                        ) : null}
                        {ticket.ticketStatus === 'REVERTED' ? (
                          <Button variant='dark' onClick={() => handleRevertedTicket(ticket.ticketId)}>Update Revert Status</Button>
                        ) : null}
                      </td>
                    </tr>
                  )).reverse()}
                </tbody>
              </Table>
            </Row>
          </>
        ) : (
          <>
            <div className='d-flex justify-content-between'>
              <div className='d-flex'>

                <Button variant={filter === 'review' ? 'secondary' : 'outline-secondary'} className='m-2' onClick={() => { setFilter('review'); setSearchTerm(''); }}>Reviewing Tickets</Button>
                <Button variant={filter === 'inProgress' ? 'primary' : 'outline-primary'} className='m-2' onClick={() => { setFilter('inProgress'); setSearchTerm(''); }}>In-Progress Tickets</Button>
                <Button variant={filter === 'closed' ? 'danger' : 'outline-danger'} className='m-2' onClick={() => { setFilter('closed'); setSearchTerm(''); }}>Closed Tickets</Button>


                <Form.Group className='d-flex' controlId="searchForm">
                  <Form.Control
                    type="text"
                    placeholder="Search by Ticket ID"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    className="m-2"
                    autoComplete="off"
                  />
                </Form.Group>

                <Button variant='dark' className='m-2' onClick={() => { setSearchTerm(''); setFilter('showAll'); }}>Clear Filter</Button>
              </div>
            </div>

            <Row className='mt-4 p-3'>
              <Table hover>
                <thead>
                  <tr>
                    <th style={{ width: '120px' }}>Ticket ID</th>
                    <th style={{ width: '120px' }}>Status</th>
                    <th style={{ width: '140px' }}>Generated On</th>
                    <th style={{ width: '140px' }}>Issue Type</th>
                    <th style={{ width: '120px' }}>Assigned To</th>
                    <th style={{ width: '120px', textAlign: 'center' }}>View Ticket</th>
                    <th style={{ textAlign: 'center', width: '140px' }}>Review Ticket</th>
                  </tr>
                </thead>
                <tbody>
                  {currentTickets.map((ticket, index) => (
                    <tr key={index}>
                      <td>{ticket.ticketId}</td>
                      <td>{ticket.ticketStatus}</td>
                      <td>{formatDate(ticket.openDate)}</td>
                      <td>{ticket.issueCategory}</td>
                      <td>{ticket.developerName ? ticket.developerName : ''}</td>
                      <td style={{ textAlign: 'center' }}>
                        <Button onClick={() => handleViewTicket(ticket.ticketId)}>View</Button>
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        {ticket.ticketStatus === 'PROGRESS' ? (
                          <Button variant='warning' onClick={() => handleReviewTicket(ticket.ticketId)}>Set Priority</Button>
                        ) : null}
                        {ticket.ticketStatus === 'REVIEWING' ? (
                          <Button variant='success' onClick={() => handleReviewTicket(ticket.ticketId)}>Add Remark</Button>
                        ) : null}
                        {ticket.ticketStatus === 'REVERTED' && filter === 'reverted' ? (
                          <div className='d-flex justify-content-between align-items-center'>
                            <Button variant='dark' onClick={() => handleRevertedTicket(ticket.ticketId)}>Update Revert Status</Button>
                          </div>
                        ) : null}
                      </td>
                    </tr>
                  )).reverse()}
                </tbody>
              </Table>
            </Row>

            <Pagination
              ticketsPerPage={ticketsPerPage}
              totalTickets={tickets.length}
              paginate={paginate}
              currentPage={currentPage}
            />
            
          </>
        )}



        <ViewHandlerTicket showViewTicket={showViewTicket} setShowViewTicket={handleCloseViewTicket} ticketDetails={selectedTicket} />
        <HandlerReviewTicket showReviewedTicket={showReviewedTicket} setShowReviewedTicket={handleCloseReviewTicket} ticketDetails={selectedTicket} />
        <HandlerRevertTicket showRevertedTicket={showRevertedTicket} setShowRevertedTicket={handleCloseRevertedTicket} ticketDetails={selectedTicket} />
      </Suspense>

    </>
  );
}

export default HandlerTickets;