import React from 'react'
import { useControllerData } from '../../../contextapi/controllerContext'
import ControllerTicketCountCard from './ControllerTicketCountCard';
import ControllerIssuePriorityCard from './ControllerIssuePriorityCard'
import ControllerIssueTypeCard from './ControllerIssueTypeCard'
import { Row, Col, Table, Button } from 'react-bootstrap'
import axios from 'axios';
import ControllerTickets from './ControllerTickets';

const updateControllerTicketApi = process.env.REACT_APP_API_UPDATE_CONTROLLER_TICKETS


export default function ControllerBody({ sidebarIndex }) {
    const { setViewSummary, updatedChanges, setUpdatedChanges, filter, setFilter, setSearchTerm } = useControllerData();

    const authToken = sessionStorage.getItem('authToken')



    const handleUpdates = async () => {
        try {
            const resp = await axios.post(updateControllerTicketApi, {
                arr: updatedChanges
            }, {
                headers: {
                    'Authorization': authToken
                }
            })
            alert(resp.data.message)
            setUpdatedChanges([])
        } catch (error) {
            alert(error.message)
        }
    }


    if (sidebarIndex === 0) {
        if (filter === 'reverted') {
            setFilter('all')
        }
        setViewSummary(true);
    } else if (sidebarIndex === 1) {
        if (filter === 'reverted') {
            setFilter('all')
        }
        setViewSummary(false);
    } else if (sidebarIndex === 2) {
        setViewSummary(true);
        setFilter('reverted')
        setSearchTerm('')
    } else if (sidebarIndex === 3) {
        setViewSummary(false);
    }
    return (
        <>
            {sidebarIndex === 0 ? (
                <>
                    {/* New Layout */}
                    < Row className='bg-light p-4' style={{ borderRadius: '1rem' }}>
                        <h4>Tickets Statistics</h4><hr />

                        {/* Priority based Stats */}
                        <Col md={3}><ControllerIssuePriorityCard /></Col>


                        {/* Type based Stats */}
                        <Col md={3}><ControllerIssueTypeCard /></Col>


                        {/* Open Tickets */}
                        <Col md={2}>
                            <ControllerTicketCountCard type={'OPEN'} />
                        </Col>

                        {/* In-Progress Tickets */}
                        <Col md={2}>
                            <ControllerTicketCountCard type={'PROGRESS'} />
                        </Col>

                        {/* Under Review Tickets */}
                        <Col md={2}>
                            <ControllerTicketCountCard type={'REVIEWING'} />
                        </Col>
                    </Row >

                    <Row className='m-2'>
                        <Col>
                            <h4>Latest Updated Tickets</h4><hr />
                            <ControllerTickets />
                        </Col>
                    </Row>
                </>
            ) : null}

            {sidebarIndex === 1 ? (
                <Row>
                    <Col className='py-4 bg-light'>
                        <ControllerTickets />
                    </Col>
                </Row>
            ) : null}

            {sidebarIndex === 2 ? (
                <Row className='p-4'>
                    <Col className='py-4 bg-light'>
                        <ControllerTickets />
                    </Col>
                </Row>
            ) : null}

            {sidebarIndex === 3 ? (
                <>
                    <Row className='m-2'>
                        <Col className='p-4 bg-light' style={{ borderRadius: '1rem', marginRight: '8px' }}>
                            <Table hover>
                                <thead>
                                    <tr>
                                        <th style={{ width: '120px' }}>Ticket ID</th>
                                        <th style={{ width: '160px' }}>Ticket Priority</th>
                                        <th style={{ width: '160px' }}>Ticket Status</th>
                                        <th>Remark</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {updatedChanges.map((ticket, index) => (
                                        <tr key={index}>
                                            <td>{ticket.ticketId}</td>
                                            <td>{ticket.ticketPriority ? ticket.ticketPriority : "Not Changed"}</td>
                                            <td>{ticket.ticketStatus ? ticket.ticketStatus : "Not Changed"}</td>
                                            <td>{ticket.remark ? ticket.remark : "Not Changed"}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='p-4 bg-light' style={{ borderRadius: '1rem', marginRight: '8px' }}>
                            <Button variant='warning' onClick={() => handleUpdates()}>Update Changes</Button>
                        </Col>
                    </Row>
                </>
            ) : null}
        </>)
}
