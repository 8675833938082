import React from 'react'
import { ProgressBar } from 'react-bootstrap'
import { useControllerData } from '../../../contextapi/controllerContext'

export default function ControllerIssueTypeCard() {
    const {tickets} = useControllerData()
    const openTickets = tickets.filter(ticket => ticket.ticketStatus !== 'CLOSED')
    
    const dhOneTickets = openTickets.filter(ticket => ticket.ticketType === 'DHUPC II')
    const dhOneTicketsCount = dhOneTickets.length;
    const dhTwoTickets = openTickets.filter(ticket => ticket.ticketType === 'DHIT')
    const dhTwoTicketsCount = dhTwoTickets.length;

    const typeNotDefined = openTickets.filter(ticket => !ticket.ticketType)
    const noTypeCount = typeNotDefined.length;
    const totalTicketsCount = openTickets.length;
    return (
        <>
            <h5 className='mb-3'>Department-Wise</h5>

            <div className='d-flex justify-content-between'>
                <p className='mb-0'>DH UPC - II</p>
                <p className='mb-0'>{dhOneTicketsCount}</p>
            </div>
            <ProgressBar className='mb-2' max={totalTicketsCount} now={dhOneTicketsCount} />


            <div className='d-flex justify-content-between'>
                <p className='mb-0'>DHIT</p>
                <p className='mb-0'>{dhTwoTicketsCount}</p>
            </div>
            <ProgressBar className='mb-2' max={totalTicketsCount} now={dhTwoTicketsCount} />
            
            
            <div className='d-flex justify-content-between'>
                <p className='mb-0'>Type Not Defined</p>
                <p className='mb-0'>{noTypeCount}</p>
            </div>
            <ProgressBar className='mb-2' max={totalTicketsCount} now={noTypeCount} />
        </>
    )
}
