import React from 'react'
import { Button } from 'react-bootstrap';
import { useDeveloperData } from '../../../contextapi/developerContext';

export default function DeveloperTicketCountCard(props) {
    const { tickets } = useDeveloperData();
    const openTickets = tickets.filter(ticket => ticket.ticketStatus === 'REVIEWING');
    const openTicketsCount = openTickets.length
    const progressTickets = tickets.filter(ticket => ticket.ticketStatus === 'PROGRESS');
    const progressTicketsCount = progressTickets.length
    const closedTickets = tickets.filter(ticket => ticket.ticketStatus === 'CLOSED');
    const closedTicketsCount = closedTickets.length
    const urgentTickets = tickets.filter(ticket => ticket.ticketPriority === 'URGENT' && ticket.ticketPriority !== 'CLOSED'
    );
    const urgentTicketsCount = urgentTickets.length

  return (
    <>
    {props.type === 'REVIEWING' ? (
        <Button  variant='warning' className='w-100 h-100'>
            <h1>{openTicketsCount}</h1>
            <p>TICKETS UNDER REVIEW</p>
        </Button>
    ) : null }
    {props.type === 'PROGRESS' ? (
        <Button  variant='primary' className='w-100 h-100'>
            <h1>{progressTicketsCount}</h1>
            <p>IN PROGRESS</p>
        </Button>
    ) : null }
    {props.type === 'CLOSED' ? (
        <Button  variant='success' className='w-100 h-100'>
            <h1>{closedTicketsCount}</h1>
            <p>TICKETS CLOSED</p>
        </Button>
    ) : null }
    {props.type === 'URGENT' ? (
        <Button  variant='danger' className='w-100 h-100'>
            <h1>{urgentTicketsCount}</h1>
            <p>URGENT TICKETS</p>
        </Button>
    ) : null }

</>
  )
}
