import React from 'react'
import { ProgressBar } from 'react-bootstrap'
import { useControllerData } from '../../../contextapi/controllerContext'


export default function ControllerIssuePriorityCard() {
    const {tickets} = useControllerData()
    const openTickets = tickets.filter(ticket => ticket.ticketStatus !== 'CLOSED')
    
    const urgentTickets = openTickets.filter(ticket => ticket.ticketPriority === "URGENT")
    const urgentTicketsCount = urgentTickets.length;
    const highTickets = openTickets.filter(ticket => ticket.ticketPriority === "HIGH")
    const highTicketsCount = highTickets.length;
    const normalTickets = openTickets.filter(ticket => ticket.ticketPriority === "NORMAL")
    const normalTicketsCount = normalTickets.length;
    const lowTickets = openTickets.filter(ticket => ticket.ticketPriority === "LOW")
    const lowTicketsCount = lowTickets.length;
    const priorityNotSet = openTickets.filter(ticket => !ticket.ticketPriority)
    const noPriorityCount = priorityNotSet.length
    const totalTickets = openTickets.length;
    return (
        <>
            <h5 className='mb-3'>Priority-Wise</h5>
            <div className='d-flex justify-content-between'>
                <p className='mb-0'>Urgent</p>
                <p className='mb-0'>{urgentTicketsCount}</p>
            </div>
            <ProgressBar className='mb-2' variant='danger' max={totalTickets} now={urgentTicketsCount} />


            <div className='d-flex justify-content-between'>
                <p className='mb-0'>High</p>
                <p className='mb-0'>{highTicketsCount}</p>
            </div>
            <ProgressBar className='mb-2' max={totalTickets} now={highTicketsCount} />


            <div className='d-flex justify-content-between'>
                <p className='mb-0'>Normal</p>
                <p className='mb-0'>{normalTicketsCount}</p>
            </div>
            <ProgressBar className='mb-2' max={totalTickets} now={normalTicketsCount} />


            <div className='d-flex justify-content-between'>
                <p className='mb-0'>Low</p>
                <p className='mb-0'>{lowTicketsCount}</p>
            </div>
            <ProgressBar className='mb-2' max={totalTickets} now={lowTicketsCount} />

            <div className='d-flex justify-content-between'>
                <p className='mb-0'>Priority Not Defined</p>
                <p className='mb-0'>{noPriorityCount}</p>
            </div>
            <ProgressBar className='mb-2' max={totalTickets} now={noPriorityCount} />
        </>
    )
}
