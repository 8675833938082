import React, { useState } from 'react'
import { useHandlerData } from '../../../contextapi/handlerContext';
import { Table } from 'react-bootstrap';

export default function HandlerDeveloperStats() {

    const { tickets } = useHandlerData();

    // const [tickets, setTickets] = useState({
    //     ticketId: '',
    //     complainantName: '',
    //     ticketStatus: '',
    //     developerName: '',
    //     ticketType: '',
    //     updateDate: '',
    //     ticketPriority: '',
    //     openDate: '',
    //     complainantEmailId: '',
    //     companyName: '',
    //     complainantPhone: '',
    // })

    const [developers, setDevelopers] = useState([
        {
            userId: 2,
            userName: 'Divya Gupta',
        },
        {
            userId: 4,
            userName: 'Mayank Raj Purbey',
        },
        {
            userId: 6,
            userName: 'Yogesh Chandra',
        },
        {
            userId: 1014,
            userName: 'Parvinder Chauhan (CIPL)',
        }
    ])

    // Initialize objects to store the ticket counts for each developer
    const progressTicketCountsByDeveloper = {};
    const closedTicketCountsByDeveloper = {};

    // Iterate over the tickets
    tickets.forEach(ticket => {
        // Check if the ticket's assignedToName matches any developer's userName
        const assignedDeveloper = developers.find(developer => developer.userName === ticket.developerName);
        if (assignedDeveloper) {
            // Increment the ticket count for the assigned developer based on the ticket status
            if (ticket.ticketStatus === 'PROGRESS') {
                if (progressTicketCountsByDeveloper[assignedDeveloper.userName]) {
                    progressTicketCountsByDeveloper[assignedDeveloper.userName]++;
                } else {
                    progressTicketCountsByDeveloper[assignedDeveloper.userName] = 1;
                }
            } else if (ticket.ticketStatus === 'CLOSED') {
                if (closedTicketCountsByDeveloper[assignedDeveloper.userName]) {
                    closedTicketCountsByDeveloper[assignedDeveloper.userName]++;
                } else {
                    closedTicketCountsByDeveloper[assignedDeveloper.userName] = 1;
                }
            }
        }
    });


    return (
        <>
            <h4>Developer's Tickets Status</h4><hr />
            <Table hover>
                <thead>
                    <tr>
                        <th>Officer / Developer</th>
                        <th style={{ width: '160px', textAlign: 'center' }}>In-Progress Tickets</th>
                        <th style={{ width: '140px', textAlign: 'center' }}>Closed Tickets</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(progressTicketCountsByDeveloper).map(([developer, progressCount]) => (
                        <tr key={developer}>
                            <td>{developer}</td>
                            <td style={{ textAlign: 'center' }}>{progressCount}</td>
                            <td style={{ textAlign: 'center' }}>{closedTicketCountsByDeveloper[developer] || 0}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    )
}
