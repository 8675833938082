import React, { useState } from 'react'
import { Row, Col, Modal, Form, Button } from 'react-bootstrap'
import formatDate from '../utils/formatDate';
import { postApiResponse } from '../utils/apiPromise';

const approveRevertTicketApi = process.env.REACT_APP_API_APPROVE_REVERT_HANDLER_TICKETS
const disapproveRevertTicketApi = process.env.REACT_APP_API_DISAPPROVE_REVERT_HANDLER_TICKETS


export default function HandlerRevertTicket(props) {

    const show = props.showRevertedTicket;
    const setShow = props.setShowRevertedTicket;
    const ticketDetails = props.ticketDetails;
    const authToken = sessionStorage.getItem('authToken')


    const [updateReview, setUpdateReview] = useState({
        remark: ""
    })

    const handleClose = () => {
        setShow(false);
    };

    const handleChange = (event) => {
        setUpdateReview({ ...updateReview, [event.target.id]: event.target.value })
    }

    const handleApproveRevertTicket = async (ticketId, updateReview) => {
        try {
            const resp = await postApiResponse(approveRevertTicketApi, {
                ticketId: ticketId,
                remark: updateReview.remark
            }, authToken)
            alert(resp.message)
            handleClose()
        } catch (error) {
            alert(error.message)
        }
    }

    const handleDisapproveRevertTicket = async (ticketId, updateReview) => {
        try {
            const resp = await postApiResponse(disapproveRevertTicketApi, {
                ticketId: ticketId,
                remark: updateReview.remark
            }, authToken)
            alert(resp.message)
            handleClose()
        } catch (error) {
            alert(error.message)
        }
    }


    return (
        <>
            {ticketDetails ? (

                <>
                    <Modal size='lg' show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                Ticket ID: {ticketDetails.ticketId}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>


                            <Row className="d-flex justify-content-between p-1">
                                <Col md={4}><strong>Company Name</strong></Col>
                                <Col>{ticketDetails.companyName}</Col>
                            </Row><hr className="mt-0 mb-2" />

                            <Row className="d-flex justify-content-between p-1">
                                <Col md={4}><strong>Complainant Email ID</strong></Col>
                                <Col>{ticketDetails.complainantEmailId}</Col>
                            </Row><hr className="mt-0 mb-2" />

                            <Row className="d-flex justify-content-between p-1">
                                <Col md={4}>
                                    <div><strong>Generated On</strong></div>
                                    <div>{formatDate(ticketDetails.openDate)}</div>
                                </Col>
                                <Col md={4}>
                                    <div><strong>Last Updated On</strong></div>
                                    <div>{formatDate(ticketDetails.updateDate)}</div>
                                </Col>
                                <Col md={4}>
                                    <div><strong>Closed Date</strong></div>
                                    <div>{formatDate(ticketDetails.closedDate)}</div>
                                </Col>
                            </Row><hr className="mt-0 mb-2" />

                            <Row className="d-flex justify-content-between p-1">
                                <Col md={4}><strong>Issue Type</strong></Col>
                                <Col>{ticketDetails.issueCategory}</Col>
                            </Row><hr className="mt-0 mb-2" />

                            <Row className="d-flex justify-content-between p-1">
                                <Col md={4}><strong>Issue Sub-Type</strong></Col>
                                <Col>{ticketDetails.issueSubcategory}</Col>
                            </Row><hr className="mt-0 mb-2" />

                            <Row className="d-flex justify-content-between p-1">
                                <Col md={4}>Remark</Col>
                                <Col>
                                    <Form.Group as={Col} md="12" controlId="remark" onChange={handleChange}>
                                        <Form.Control
                                            as="textarea"
                                            placeholder="Write your remark here... (Optional)"
                                            autoComplete="off"
                                            style={{ height: '20vh' }}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant='danger' onClick={() => handleDisapproveRevertTicket(ticketDetails.ticketId, updateReview)}>Send Back to Officer</Button>
                            <Button variant='success' onClick={() => handleApproveRevertTicket(ticketDetails.ticketId, updateReview)}>Accept Revert</Button>
                        </Modal.Footer>
                    </Modal>
                </>
            ) : null}
        </>
    )
}
