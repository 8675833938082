import React, { useState, useEffect, Suspense } from 'react'
import { useNavigate } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import axios from 'axios'

import CommonNavbar from '../components/common/CommonNavbar'
import Loading from '../components/common/Loading'
import ProfileCard from '../components/cards/ProfileCard'
import ControllerBody from '../components/cards/controllers/ControllerBody'

import { useControllerData } from '../contextapi/controllerContext';
import { useUserDetails } from '../contextapi/userDetails'

import { controllerMenu } from '../assets/arrays/sideBarMenu'


const controllerDashboardApi = process.env.REACT_APP_API_CONTROLLER_DASHBOARD
const fetchUsersApi = process.env.REACT_APP_API_FETCH_USERS

export default function ControllerDashboard() {


  const authToken = sessionStorage.getItem('authToken') // To fetch AuthToken


  const { setUserDetails } = useUserDetails();
  const { setHandlers, setDevelopers } = useControllerData();
  const sideMenu = controllerMenu
  const [sidebarIndex, setSidebarIndex] = useState(0)

  const [fetchControllerApi, setFetchControllerApi] = useState(false)
  const [fetchUserDetailsApi, setFetchUserDetailsApi] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    setFetchControllerApi(true)
    setFetchUserDetailsApi(true)
  }, []);

  useEffect(() => {
    if (fetchControllerApi) {
      const fetchData = async () => {
        try {
          // Fetch controller details
          const controllerResponse = await insertResponses(controllerDashboardApi, authToken)
          setUserDetails(controllerResponse.userDetails)
        } catch (error) {
          alert(error);
          navigate('/');
        }
      }
      fetchData()
    }
  }, [authToken, fetchControllerApi, navigate, setUserDetails])



  useEffect(() => {
    if (fetchUserDetailsApi) {
      const fetchData = async () => {
        try {
          // Fetch user details
          const fetchUserResponse = await insertResponses(fetchUsersApi, authToken)
          setHandlers(fetchUserResponse.handlers)
          setDevelopers(fetchUserResponse.developers)
        } catch (error) {
          alert(error);
          navigate('/');
        }
      }
      fetchData()
    }
  }, [authToken, fetchUserDetailsApi, navigate, setDevelopers, setHandlers])


  return (
    <>
      <Row>
        <CommonNavbar />
      </Row>

      <Suspense fallback={<Loading />}>
        <Row>
          <Col md={2} className='text-center p-4 bg-dark text-white'>
            <ProfileCard
              setSidebarIndex={setSidebarIndex}
              sideMenu={sideMenu}
            />
          </Col>


          <Col md={10}>
            <ControllerBody sidebarIndex={sidebarIndex} />
          </Col>
        </Row >
      </Suspense>
    </>
  )
}

const insertResponses = (api, authToken) => {
  return new Promise(async (resolve, reject) => {
    await axios.get(api, {
      headers: {
        'Authorization': authToken
      }
    })
      .then(apiResponse => resolve(apiResponse.data))
      .catch(error => reject(error.message))
  })
}