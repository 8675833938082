import axios from 'axios'

const getApiResponse = (api, authToken) => {
  return new Promise(async (resolve, reject) => {
    await axios.get(api, {
      headers: {
        'Authorization': authToken
      }
    })
      .then(apiResponse => resolve(apiResponse.data))
      .catch(error => reject(error.message))
  })
}
export { getApiResponse };



const postApiResponse = (api, body, authToken) => {
  return new Promise(async (resolve, reject) => {
    await axios.post(api, body, {
      headers: {
        'Content-Type' : 'application/json',
        'Authorization': authToken
      }
    })
      .then(apiResponse => resolve(apiResponse.data))
      .catch(error => reject(error.message))
  })
}
export { postApiResponse };


const multipartApiResponse = (api, body, authToken) => {
  return new Promise(async (resolve, reject) => {
    await axios.post(api, body, {
      headers: {
        'Content-Type' : 'multipart/form-data',
        'Authorization': authToken
      }
    })
      .then(apiResponse => resolve(apiResponse.data))
      .catch(error => reject(error.message))
  })
}
export { multipartApiResponse };


const loginApiResponse = (api, body) => {
  return new Promise(async (resolve, reject) => {
    await axios.post(api, body, {
      headers:{
        'Content-Type' : 'application/json'
      }
    })
      .then(apiResponse => resolve(apiResponse.data))
      .catch(error => reject(error.message))
  })
}
export { loginApiResponse };