import React, { useState, useEffect } from 'react'
import { Form, Row, InputGroup, Button, Col, Alert } from 'react-bootstrap'
import loginFormStructure, { complainantLoginStructure } from '../utils/loginFormStructure'
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import roles from '../assets/arrays/roleTypes';
import { ErrorMessage } from '../modals/ErrorMessage';

const userLoginApi = process.env.REACT_APP_API_USER_LOGIN

export default function UserLogin(props) {
    const setShow = props.setShow
    const loginSource = props.source
    const [fieldValidate, setFieldValidate] = useState({
        password: true,
        emailId: true
    });

    const roleTypes = roles.slice(0, -1)
    const [validated, setValidated] = useState(false)
    const [user, setUser] = useState(loginSource === 'organisation' ? loginFormStructure : complainantLoginStructure)

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorTitle, setErrorTitle] = useState('');

    const navigate = useNavigate();

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            axios.post(userLoginApi, user, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                const responseData = response.data.userDetails
                sessionStorage.setItem('authToken', response.data.userDetails.authToken)
                sessionStorage.setItem('userRole', response.data.userDetails.userRole)
                if (response.data.passwordReset === true) {
                    alert('Password update is required')
                    sessionStorage.removeItem('authToken')
                    navigate('/passwordreset')
                } else if (responseData.userRoleId === 1) {
                    navigate('/admin')
                } else if (responseData.userRoleId === 2) {
                    navigate('/controller')
                } else if (responseData.userRoleId === 3) {
                    navigate('/handler')
                } else if (responseData.userRoleId === 4) {
                    navigate('/officer')
                } else if (responseData.userRoleId === 5) {
                    navigate('/complainant')
                }
            }).catch(async (error) => {
                setErrorTitle('Login Failed');
                setErrorMessage(error.response.data.message);
                setShowErrorModal(true);
            })
        }
        setValidated(true);
    };

    const handleChange = (event) => {
        const { id, value, type, name } = event.target;
        const passwordPattern = /^[a-zA-Z0-9@.\-]+$/;
        const phoneNumberPattern = /^[0-9]{10}$/; // Example pattern for 10-digit phone number
        // const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        let isValid

        if (type === 'radio' && name === 'roleType') {
            setUser({ ...user, userRole: value });
        } else if (id === 'password') {
            isValid = passwordPattern.test(value) || value === '';
            setFieldValidate({ ...fieldValidate, password: isValid })
            setUser({ ...user, [id]: value })
        } else if (id === 'emailId') {
            isValid = emailPattern.test(value) || value === '';
            setFieldValidate({ ...fieldValidate, emailId: isValid })
            setUser({ ...user, [id]: value })
        } else {
            setUser({ ...user, [id]: value })
        }
    }


    const handleCloseErrorModal = () => {
        setShowErrorModal(false);
        setShow(false)
    };

    return (
        <>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>

                {/* User Email ID */}
                <Row className="mb-3">
                    <Form.Group controlId="emailId" onChange={handleChange} required>
                        <Form.Label>Email Id</Form.Label>
                        <InputGroup hasValidation>
                            <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                            <Form.Control
                                type="email"
                                placeholder="email id"
                                aria-describedby="inputGroupPrepend"
                                isInvalid={!fieldValidate.emailId}
                                autoComplete="off"
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid email Id.
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                </Row >

                {/* User Password */}
                <Row className="mb-3">
                    <Form.Group className="mb-3" controlId="password" onChange={handleChange} required>
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Password"
                            isInvalid={!fieldValidate.password}
                            autoComplete="off"
                            required />
                        <Form.Control.Feedback type="invalid">
                            Password must only contain letters, numbers, '@', '.', and '-'.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>

                {/* Select Role */}
                {loginSource === 'organisation' ? (
                    <Row className="mb-3">
                        <Form.Group controlId="userRole" onChange={handleChange}>
                            <Form.Label>Select the role type</Form.Label>
                            <div key={`userRole`}>
                                {roleTypes.map((role, index) => (
                                    <Form.Check
                                        key={index + 1}
                                        inline
                                        label={role.label}
                                        type='radio'
                                        name='roleType'
                                        id={`roleType_${role.value}`}
                                        value={role.value}
                                        required={index === 0} // Make the first radio button required
                                    />
                                ))}
                            </div>
                        </Form.Group>
                    </Row>
                ) : null}



                <Row className="justify-content-end">
                    <Col className="text-end">
                        <Button type='submit' className='btn btn-primary'>Submit</Button>
                    </Col>
                </Row>
            </Form >

            <ErrorMessage show={showErrorModal} handleClose={handleCloseErrorModal} errorTitle={errorTitle} errorMessage={errorMessage} />
        </>
    )
}
