import React, { useState, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import CommonNavbar from '../components/common/CommonNavbar'
import { useLocation } from 'react-router-dom'
import { postApiResponse } from '../utils/apiPromise'
import formatDate from '../utils/formatDate'
import TicketTrail from '../components/cards/TicketTrail'
import { useUserDetails } from '../contextapi/userDetails'
import TrailProfileCard from '../components/cards/TrailProfileCard'

const fetchTicketTrailApi = process.env.REACT_APP_API_FETCH_TICKET_TRAIL


export default function TicketTrailView() {

    const location = useLocation();
    const selectedTicket = location.state && location.state.ticketId;

    const [fetchTrailApi, setFetchTrailApi] = useState(false)
    const [ticketTrail, setTicketTrail] = useState([])

    const authToken = sessionStorage.getItem('authToken')
    const { userDetails } = useUserDetails()

    useEffect(() => {
        setFetchTrailApi(true)
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const ticketTrailResponse = await postApiResponse(fetchTicketTrailApi, {
                    ticketId: selectedTicket
                }, authToken)
                setTicketTrail(ticketTrailResponse.ticketTrail)
            } catch (error) {
                alert(error)
            }
        }
        if (fetchTrailApi) {
            fetchData()
        }
    }, [fetchTrailApi])

    // Get the current date
    const currentDate = new Date();

    // Calculate tomorrow's date by adding one day to the current date
    const tomorrow = new Date(currentDate);
    tomorrow.setDate(tomorrow.getDate() + 1);

    // Set the time to midnight
    tomorrow.setHours(0, 0, 0, 0);

    // Filter ticketTrail to include only objects with updateDate above thresholdDate
    const filteredDates = ticketTrail.filter(ticket => new Date(ticket.updateDate) < tomorrow);

    // Extract unique dates
    const uniqueDates = [...new Set(filteredDates.map(ticket => formatDate(ticket.updateDate)))];


    return (
        <>
            <Row>
                <CommonNavbar />
            </Row>
            <Row>
                <Col md={2} className='text-center p-4 bg-dark text-white'>
                    <TrailProfileCard />
                </Col>

                <Col md={8} className='my-2'>
                    <TicketTrail ticketId={selectedTicket} ticketTrail={ticketTrail} uniqueDates={uniqueDates} />
                </Col>
                <Col md={2}></Col>
            </Row>
        </>
    )
}
