import React, { Suspense, useEffect, useState, useReducer } from 'react'
import { Row, Table, Button, Col, Form } from 'react-bootstrap'
import { useDeveloperData } from '../../../contextapi/developerContext';
import { getApiResponse, postApiResponse } from '../../../utils/apiPromise';
import { useNavigate } from 'react-router-dom';
import formatDate from '../../../utils/formatDate';
import Loading from '../../common/Loading';
import DeveloperReviewTicket from '../../../modals/DeveloperReviewTicket'
import ViewDeveloperTicket from '../../../modals/ViewDeveloperTicket';
import Pagination from '../../common/Pagination';

const fetchDeveloperTicketsApi = process.env.REACT_APP_API_FETCH_DEVELOPER_TICKETS
const revertDeveloperTicketApi = process.env.REACT_APP_API_REVERT_DEVELOPER_TICKET

const initialState = {
    selectedTicketId: null,
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'SELECT_TICKET':
            return { ...state, selectedTicketId: action.payload };
        default:
            return state;
    }
};



export default function DeveloperTickets() {
    const [fetchTicketsApi, setFetchTicketsApi] = useState(false)
    const [showReviewedTicket, setShowReviewedTicket] = useState(false)
    const [showViewTicket, setShowViewTicket] = useState(false)
    const [searchTerm, setSearchTerm] = useState('');

    const { tickets, setTickets, updatedChanges, setUpdatedChanges, viewSummary, filter, setFilter } = useDeveloperData();
    const [state, dispatch] = useReducer(reducer, initialState);
    const { selectedTicketId } = state
    const authToken = sessionStorage.getItem('authToken')
    const navigate = useNavigate()

    useEffect(() => {
        setFetchTicketsApi(true)
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const ticketResponse = await getApiResponse(fetchDeveloperTicketsApi, authToken)
                setTickets(ticketResponse.tickets)
            } catch (error) {
                alert(error);
                navigate('/');
            }
        }
        if (fetchTicketsApi) {
            fetchData()
        }
    }, [fetchTicketsApi, updatedChanges])



    const handleViewTicket = (ticketId) => {
        setShowViewTicket(true)
        dispatch({ type: 'SELECT_TICKET', payload: ticketId });
    };

    const handleCloseViewTicket = () => {
        setShowViewTicket(false)
        dispatch({ type: 'SELECT_TICKET', payload: null });
    };


    const handleReviewTicket = (ticketId) => {
        setShowReviewedTicket(true)
        dispatch({ type: 'SELECT_TICKET', payload: ticketId });
    };

    const handleCloseReviewTicket = () => {
        setShowReviewedTicket(false)
        dispatch({ type: 'SELECT_TICKET', payload: null });
    };

    const handleRevertTicket = async (ticketId) => {
        try {
            const resp = await postApiResponse(revertDeveloperTicketApi, {
                ticketId: ticketId
            }, authToken)
            alert(resp.message)
        } catch (error) {
            alert(error.message)
        }
    }

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setCurrentPage(1); // Reset pagination to first page when searching
    }


    // Function to filter tickets by search term
    const filterBySearchTerm = (tickets, searchTerm) => {
        return tickets.filter(ticket =>
            ticket.ticketId.toLowerCase().includes(searchTerm.toLowerCase())
        );
    };

    // Function to filter tickets by status
    const filterByStatus = (tickets, filter) => {
        switch (filter) {
            case 'review':
                return tickets.filter(ticket => ticket.ticketStatus === 'REVIEWING');
            case 'closed':
                return tickets.filter(ticket => ticket.ticketStatus === 'CLOSED');
            case 'inProgress':
                return tickets.filter(ticket => ticket.ticketStatus === 'PROGRESS');
            case 'reverted':
                return tickets.filter(ticket => ticket.ticketStatus === 'REVERTED');
            case 'urgent':
                return tickets.filter(ticket => ticket.ticketPriority === 'URGENT');
            case 'showAll':
                return tickets;
            default:
                return tickets;
        }
    };

    // Main function to filter tickets
    const filteredTickets = () => {
        if (searchTerm) {
            return filterBySearchTerm(tickets, searchTerm);
        } else {
            return filterByStatus(tickets, filter);
        }
    };






    // For Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const ticketsPerPage = 20;
    const indexOfLastTicket = currentPage * ticketsPerPage;
    const indexOfFirstTicket = indexOfLastTicket - ticketsPerPage;

    const sortedTickets = filteredTickets().sort((a, b) => new Date(b.openDate) - new Date(a.openDate));
    const currentTickets = sortedTickets.slice(indexOfFirstTicket, indexOfLastTicket);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    let selectedTicket = tickets.find(ticket => ticket.ticketId === selectedTicketId);


    return (
        <>
            <Suspense fallback={<Loading />}>
                {viewSummary ? (
                    <>
                        <Row>
                            <Table hover>
                                <thead>
                                    <tr>
                                        <th style={{ width: '120px' }}>Ticket ID</th>
                                        <th style={{ width: '120px' }}>Status</th>
                                        <th style={{ width: '240px' }}>Complainant</th>
                                        <th style={{ width: '140px' }}>Generated On</th>
                                        <th>Issue Type</th>
                                        <th style={{ width: '120px', textAlign: 'center' }}>View</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentTickets.slice(-6).map((ticket, index) => (
                                        <tr key={index}>
                                            <td className={ticket.ticketPriority === 'URGENT' ? 'bg-danger text-white' : ''}>{ticket.ticketId}</td>
                                            <td>{ticket.ticketStatus}</td>
                                            <td>{ticket.complainantName}</td>
                                            <td>{formatDate(ticket.openDate)}</td>
                                            <td>{ticket.issueCategory}</td>
                                            <td style={{ textAlign: 'center' }}>
                                                <Button onClick={() => handleViewTicket(ticket.ticketId)}>View</Button>
                                            </td>
                                        </tr>
                                    )).reverse()}
                                </tbody>
                            </Table>
                        </Row>
                    </>
                ) : (
                    <>
                        <div className='d-flex justify-content-between'>
                            <div className='d-flex'>
                                <Button variant={filter === 'inProgress' ? 'primary' : 'outline-primary'} className='m-2' onClick={() => { setFilter('inProgress'); setSearchTerm(''); }}>In-Progress Tickets</Button>
                                <Button variant={filter === 'review' ? 'secondary' : 'outline-secondary'} className='m-2' onClick={() => { setFilter('review'); setSearchTerm(''); }}>Under Review</Button>
                                <Button variant={filter === 'danger' ? 'success' : 'outline-danger'} className='m-2' onClick={() => { setFilter('urgent'); setSearchTerm(''); }}>Show Urgent Tickets</Button>
                                <Form.Group className='d-flex' controlId="searchForm">
                                    <Form.Control
                                        type="text"
                                        placeholder="Search by Ticket ID"
                                        value={searchTerm}
                                        onChange={handleSearchChange}
                                        className="m-2"
                                        autoComplete="off"
                                    />
                                </Form.Group>
                                <Button variant='dark' className='m-2' onClick={() => { setFilter('showAll'); setSearchTerm(''); }}>Clear Filter</Button>
                            </div>
                            <div className='d-flex'>
                            </div>
                        </div >
                        <hr variant='dark' className='mt-2 mb-0' />
                        <Row className='mt-2 p-3'>
                            <Table hover>
                                <thead>
                                    <tr>
                                        <th style={{ width: '120px' }}>Ticket ID</th>
                                        <th style={{ width: '120px' }}>Status</th>
                                        <th>Complainant</th>
                                        <th style={{ width: '140px' }}>Generated On</th>
                                        <th style={{ width: '120px', textAlign: 'center' }}>View</th>
                                        <th style={{ width: '140px', textAlign: 'center' }}>Review Ticket</th>
                                        <th style={{ width: '140px', textAlign: 'center' }}>Request Revert</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentTickets.map((ticket, index) => (
                                        <tr key={index}>
                                            <td className={ticket.ticketPriority === 'URGENT' ? 'bg-danger text-white' : ''}>{ticket.ticketId}</td>
                                            <td>{ticket.ticketStatus}</td>
                                            <td>{ticket.complainantName}</td>
                                            <td>{formatDate(ticket.openDate)}</td>
                                            <td style={{ textAlign: 'center' }}>
                                                <Button onClick={() => handleViewTicket(ticket.ticketId)}>View</Button>
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                                {ticket.ticketStatus === 'PROGRESS' ? (
                                                    <Button variant='success' onClick={() => handleReviewTicket(ticket.ticketId)}>Add Remark</Button>
                                                ) : null}
                                                {ticket.ticketStatus === 'REVIEWING' ? (
                                                    <Button variant='dark' disabled onClick={() => handleReviewTicket(ticket.ticketId)}>Under Review</Button>
                                                ) : null}
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                                {ticket.ticketStatus === 'PROGRESS' ? (
                                                    <Button variant='warning' onClick={() => handleRevertTicket(ticket.ticketId)}>Revert</Button>
                                                ) : null}
                                            </td>
                                        </tr>
                                    )).reverse()}
                                </tbody>
                            </Table>
                        </Row>

                        <Pagination
                            ticketsPerPage={ticketsPerPage}
                            totalTickets={tickets.length}
                            paginate={paginate}
                            currentPage={currentPage}
                        />
                    </>
                )
                }
                <ViewDeveloperTicket showViewTicket={showViewTicket} setShowViewTicket={handleCloseViewTicket} ticketDetails={selectedTicket} />
                <DeveloperReviewTicket showReviewedTicket={showReviewedTicket} setShowReviewedTicket={handleCloseReviewTicket} ticketDetails={selectedTicket} />
            </Suspense >
        </>
    )
}
