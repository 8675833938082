import React from 'react'
import { Button } from 'react-bootstrap';
import { useControllerData } from '../../../contextapi/controllerContext';

export default function ControllerTicketCountCard(props) {
    const { tickets } = useControllerData();
    const openTickets = tickets.filter(ticket => ticket.ticketStatus === 'OPEN');
    const openTicketsCount = openTickets.length
    const progressTickets = tickets.filter(ticket => ticket.ticketStatus === 'PROGRESS');
    const progressTicketsCount = progressTickets.length
    const reviewingTickets = tickets.filter(ticket => ticket.ticketStatus === 'REVIEWING');
    const reviewingTicketsCount = reviewingTickets.length
    const closedTickets = tickets.filter(ticket => ticket.ticketStatus === 'CLOSED');
    const closedTicketsCount = closedTickets.length

    return (
        <>
            {props.type === 'OPEN' ? (
                <Button variant='success' className='w-100 h-100'>
                    <h1>{openTicketsCount}</h1>
                    <p>UNASSIGNED TICKETS</p>
                </Button>
            ) : null }
            {props.type === 'PROGRESS' ? (
                <Button variant='warning' className='w-100 h-100'>
                    <h1>{progressTicketsCount}</h1>
                    <p>TICKETS<br/>IN-PROGRESS</p>
                </Button>
            ) : null }
            {props.type === 'CLOSED' ? (
                <Button variant='danger' className='w-100 h-100'>
                    <h1>{closedTicketsCount}</h1>
                    <p>CLOSED TICKETS</p>
                </Button>
            ) : null }
            {props.type === 'REVIEWING' ? (
                <Button variant='secondary' className='w-100 h-100'>
                    <h1>{reviewingTicketsCount}</h1>
                    <p>TICKETS<br/>UNDER REVIEW</p>
                </Button>
            ) : null }

        </>
    )
}
