import React from 'react'
import { Row, Col, Table, Button } from 'react-bootstrap'
import HandlerTicketCountCard from './HandlerTicketCountCard'
import HandlerTickets from './HandlerTickets'
import { useHandlerData } from '../../../contextapi/handlerContext'
import { postApiResponse } from '../../../utils/apiPromise'
import { useNavigate } from 'react-router-dom'
import HandlerDeveloperStats from './HandlerDeveloperStats'
import HandlerTicketCounts from './HandlerTicketCounts'

const updateHandlerTicketApi = process.env.REACT_APP_API_UPDATE_HANDLER_TICKETS


export default function HandlerBody({ sidebarIndex }) {
    const { setViewSummary, setSearchTerm, setFilter, updatedChanges, setUpdatedChanges, filter } = useHandlerData()

    const authToken = sessionStorage.getItem('authToken')
    const navigate = useNavigate()

    const handleUpdates = async () => {
        try {
            const updateResponse = await postApiResponse(updateHandlerTicketApi, {
                arr: updatedChanges
            }, authToken)
            alert(updateResponse.message)
            setUpdatedChanges([])
        } catch (error) {
            alert(error);
            navigate('/');
        }
    }

    if (sidebarIndex === 0) {
        setSearchTerm('')
        setFilter('all')
        setViewSummary(true)
    } else if (sidebarIndex === 1) {
        if (filter === 'reverted') {
            setFilter('all')
        }
        setViewSummary(false)
    } else if (sidebarIndex === 2) {
        setViewSummary(true)
        setFilter('reverted')
        setSearchTerm('')
    } else if (sidebarIndex === 3) {
        setViewSummary(false)
        setFilter('all')
        setSearchTerm('')
    }





    return (
        <>

            {/* To view Dashboard */}
            {sidebarIndex === 0 ? (
                <>
                    <Row className='m-2 bg-light p-4' style={{ borderRadius: '1rem' }}>

                        {/* In-Review Tickets */}
                        <Col md={3}>
                            <HandlerTicketCountCard type={'REVIEWING'} />
                        </Col>

                        {/* In Progress Tickets */}
                        <Col md={3}>
                            <HandlerTicketCountCard type={'PROGRESS'} />
                        </Col>

                        {/* Closed Tickets */}
                        <Col md={3}>
                            <HandlerTicketCountCard type={'CLOSED'} />
                        </Col>

                        {/* Open Tickets */}
                        <Col md={3}>
                            <HandlerTicketCountCard type={'URGENT'} />
                        </Col>
                    </Row>

                    <Row className='m-2 bg-light p-4' style={{ borderRadius: '1rem', display: 'flex', justifyContent: 'space-between' }}>
                        <Col md={6}>
                            <HandlerDeveloperStats />
                        </Col>
                        <Col md={6}>
                            <HandlerTicketCounts />
                        </Col>
                    </Row>

                    <Row className='m-2'>
                        <Col className='p-4 bg-light' style={{ borderRadius: '1rem', marginRight: '8px' }}>
                            <HandlerTickets />
                        </Col>
                    </Row>
                </>
            ) : null}

            {/* To view All the Tickets */}
            {sidebarIndex === 1 ? (
                <>
                    <Row className='m-2'>
                        <Col className='p-4 bg-light' style={{ borderRadius: '1rem', marginRight: '8px' }}>
                            <HandlerTickets />
                        </Col>
                    </Row>
                </>
            ) : null}

            {/* To view Only Reverted Tickets */}
            {sidebarIndex === 2 ? (
                <>
                    <Row className='m-2'>
                        <Col className='p-4 bg-light' style={{ borderRadius: '1rem', marginRight: '8px' }}>
                            <HandlerTickets />
                        </Col>
                    </Row>
                </>
            ) : null}

            {/* Post Update Changes to database */}
            {sidebarIndex === 3 ? (
                <>
                    <Row className='m-2'>
                        <Col className='p-4 bg-light' style={{ borderRadius: '1rem', marginRight: '8px' }}>
                            <Table hover>
                                <thead>
                                    <tr>
                                        <th style={{ width: '120px' }}>Ticket ID</th>
                                        <th style={{ width: '160px' }}>Ticket Priority</th>
                                        <th style={{ width: '160px' }}>Ticket Status</th>
                                        <th>Remark</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {updatedChanges.map((ticket, index) => (
                                        <tr key={index}>
                                            <td>{ticket.ticketId}</td>
                                            <td>{ticket.ticketPriority ? ticket.ticketPriority : "Not Changed"}</td>
                                            <td>{ticket.ticketStatus ? ticket.ticketStatus : "Not Changed"}</td>
                                            <td>{ticket.remark ? ticket.remark : "Not Changed"}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='p-4 bg-light' style={{ borderRadius: '1rem', marginRight: '8px' }}>
                            <Button variant='warning' onClick={() => handleUpdates()}>Update Changes</Button>
                        </Col>
                    </Row>
                </>
            ) : null}
        </>
    )
}
