import React, { useState, useEffect, Suspense } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import CommonNavbar from '../components/common/CommonNavbar'
import { getApiResponse } from '../utils/apiPromise'
import { DeveloperDataProvider } from '../contextapi/developerContext'
import DeveloperTickets from '../components/cards/developers/DeveloperTickets'
import Loading from '../components/common/Loading'
import DeveloperTicketCountCard from '../components/cards/developers/DeveloperTicketCountCard'
import { useUserDetails } from '../contextapi/userDetails'
import ProfileCard from '../components/cards/ProfileCard'
import { developerMenu } from '../assets/arrays/sideBarMenu'
import DeveloperBody from '../components/cards/developers/DeveloperBody'

const developerDashboardApi = process.env.REACT_APP_API_DEVELOPER_DASHBOARD

export default function DeveloperDashboard() {
    const { setUserDetails } = useUserDetails() // Contains user Details : getRequest
    const authToken = sessionStorage.getItem('authToken') // Fetch AuthToken

    const sideMenu = developerMenu
    const [sidebarIndex, setSidebarIndex] = useState(0)

    const [fetchUserApi, setFetchUserApi] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        setFetchUserApi(true)
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const userResponse = await getApiResponse(developerDashboardApi, authToken)
                setUserDetails(userResponse.userDetails)
            } catch (error) {
                alert(error);
                navigate('/');
            }
        }
        if (fetchUserApi) {
            fetchData()
        }
    }, [authToken, fetchUserApi, navigate, setUserDetails])

    return (
        <>
            <DeveloperDataProvider>
                <Row>
                    <CommonNavbar />
                </Row>
                <Suspense fallback={<Loading />}>
                    {authToken ? (
                        <Row>
                            {/* User Details */}
                            <Col md={2} className='text-center p-4 bg-dark text-white'>
                                <ProfileCard
                                    setSidebarIndex={setSidebarIndex}
                                    sideMenu={sideMenu}
                                />
                            </Col>

                            <Col>
                                <DeveloperBody sidebarIndex={sidebarIndex} />
                            </Col>
                        </Row >
                    ) : null}
                </Suspense>
            </DeveloperDataProvider>
        </>
    )
}
