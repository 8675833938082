import React, { useReducer, useState, useEffect } from 'react'
import { Row, Button, Table, Form } from 'react-bootstrap'
import ControllerAssignTicket from '../../../modals/ControllerAssignTicket';
import { useNavigate } from 'react-router-dom';
import { useControllerData } from '../../../contextapi/controllerContext';
import formatDate from '../../../utils/formatDate';
import { getApiResponse } from '../../../utils/apiPromise';
import ViewTicket from '../../../modals/ViewTicket';
import Pagination from '../../common/Pagination';

const fetchControllerTicketsApi = process.env.REACT_APP_API_FETCH_CONTROLLER_TICKETS


const initialState = {
    selectedTicketId: null,
};



const reducer = (state, action) => {
    switch (action.type) {
        case 'SELECT_TICKET':
            return { ...state, selectedTicketId: action.payload };
        default:
            return state;
    }
};



export default function ControllerTickets() {
    const { tickets, setTickets, updatedChanges, viewSummary, filter, setFilter } = useControllerData();

    const [state, dispatch] = useReducer(reducer, initialState);
    const { selectedTicketId } = state

    const [showViewTicket, setShowViewTicket] = useState(false)
    const [showAssignTicket, setShowAssignTicket] = useState(false)
    const [searchTerm, setSearchTerm] = useState('');

    const [fetchTicketsApi, setFetchTicketsApi] = useState(false)
    const navigate = useNavigate()
    const authToken = sessionStorage.getItem('authToken')




    useEffect(() => {
        setFetchTicketsApi(true)
    }, [])

    useEffect(() => {
        if (fetchTicketsApi) {
            const fetchData = async () => {
                try {
                    // Fetch controller tickets
                    const controllerResponse = await getApiResponse(fetchControllerTicketsApi, authToken)
                    setTickets(controllerResponse.tickets)
                } catch (error) {
                    alert(error);
                    navigate('/');
                }
            }
            fetchData()
        }
    }, [fetchTicketsApi, updatedChanges])


    const handleViewTicket = (ticketId) => {
        setShowViewTicket(true)
        dispatch({ type: 'SELECT_TICKET', payload: ticketId });
    };

    const handleCloseViewTicket = () => {
        setShowViewTicket(false)
        dispatch({ type: 'SELECT_TICKET', payload: null });
    };

    const handleAssignTicket = (ticketId) => {
        setShowAssignTicket(true)
        dispatch({ type: 'SELECT_TICKET', payload: ticketId });
    };

    const handleCloseAssignTicket = () => {
        setShowAssignTicket(false)
        dispatch({ type: 'SELECT_TICKET', payload: null });
    };


    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setCurrentPage(1); // Reset pagination to first page when searching
    }


    // Function to filter tickets by search term
    const filterBySearchTerm = (tickets, searchTerm) => {
        return tickets.filter(ticket =>
            ticket.ticketId.toLowerCase().includes(searchTerm.toLowerCase())
        );
    };

    // Function to filter tickets by status
    const filterByStatus = (tickets, filter) => {
        switch (filter) {
            case 'review':
                return tickets.filter(ticket => ticket.ticketStatus === 'REVIEWING');
            case 'closed':
                return tickets.filter(ticket => ticket.ticketStatus === 'CLOSED');
            case 'inProgress':
                return tickets.filter(ticket => ticket.ticketStatus === 'PROGRESS');
            case 'reverted':
                return tickets.filter(ticket => ticket.ticketStatus === 'REVERTED' && ticket.ticketType === null);
            case 'unassigned':
                return tickets.filter(ticket => ticket.ticketStatus === 'OPEN' || (ticket.ticketStatus === 'REVERTED' && ticket.ticketType === null));
            case 'showAll':
                return tickets;
            default:
                return tickets;
        }
    };

    // Main function to filter tickets
    const filteredTickets = () => {
        if (searchTerm) {
            return filterBySearchTerm(tickets, searchTerm);
        } else {
            return filterByStatus(tickets, filter);
        }
    };


    // For Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const ticketsPerPage = 20;

    const indexOfLastTicket = currentPage * ticketsPerPage;
    const indexOfFirstTicket = indexOfLastTicket - ticketsPerPage;


    // Sort tickets by generated date in descending order
    const sortedTickets = filteredTickets().sort((a, b) => new Date(b.openDate) - new Date(a.openDate));
    const currentTickets = sortedTickets.slice(indexOfFirstTicket, indexOfLastTicket);


    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    let selectedTicket = tickets.find(ticket => ticket.ticketId === selectedTicketId);


    return (
        <>
            {viewSummary ? (
                <>
                    <Row className='mt-1'>
                        <Table hover>
                            <thead>
                                <tr>
                                    <th style={{ width: '120px' }}>Ticket ID</th>
                                    <th style={{ width: '120px' }}>Status</th>
                                    <th style={{ width: '120px' }}>Generated</th>
                                    <th>Subject</th>
                                    <th style={{ textAlign: 'center', width: '120px' }}>View Ticket</th>
                                    <th style={{ textAlign: 'center', width: '200px' }}>Assign Ticket</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentTickets.slice(filter === 'reverted' ? 0 : -6).map((ticket, index) => (
                                    <tr key={index}>
                                        <td>{ticket.ticketId}</td>
                                        <td>{ticket.ticketStatus}</td>
                                        <td>{formatDate(ticket.openDate)}</td>
                                        <td>{ticket.issue}</td>
                                        <td style={{ textAlign: 'center' }}>
                                            <Button onClick={() => handleViewTicket(ticket.ticketId)}>View</Button>
                                        </td>
                                        <td style={{ textAlign: 'center' }}>
                                            {ticket.ticketStatus === 'OPEN' ?
                                                <Button variant='success' onClick={() => handleAssignTicket(ticket.ticketId)}>Assign</Button> : null
                                            }


                                            {((ticket.ticketStatus === 'REVERTED' && ticket.ticketType === null) || ticket.ticketStatus === 'PROGRESS') ?
                                                <Button variant='secondary' onClick={() => handleAssignTicket(ticket.ticketId)}>Re-Assign</Button> : null
                                            }
                                        </td>
                                    </tr>
                                )).reverse()}
                            </tbody>
                        </Table>
                    </Row>
                </>

            ) : (

                <>
                    <div className='m-4 d-flex justify-content-between'>
                        <div className='d-flex'>
                            <Button variant={filter === 'unassigned' ? 'success' : 'outline-success'} className='m-2' onClick={() => { setFilter('unassigned'); setSearchTerm(''); }}>Show Unassigned Tickets</Button>
                            <Button variant={filter === 'closed' ? 'danger' : 'outline-danger'} className='m-2' onClick={() => { setFilter('closed'); setSearchTerm(''); }}>Show Closed Tickets</Button>
                            <Form.Group className='d-flex' controlId="searchForm">
                                <Form.Control
                                    type="text"
                                    placeholder="Search by Ticket ID"
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                    className="m-2"
                                />
                            </Form.Group>
                            <Button variant='dark' className='m-2' onClick={() => { setFilter('showAll'); setSearchTerm(''); }}>Clear Filter</Button>
                        </div>
                    </div>


                    <Row className='p-3 m-2'>
                        <Table hover>
                            <thead>
                                <tr>
                                    <th style={{ width: '120px' }}>Ticket ID</th>
                                    <th style={{ width: '120px' }}>Status</th>
                                    <th style={{ width: '140px' }}>Generated On</th>
                                    <th>Issue Type</th>
                                    <th style={{ width: '120px' }}>Division</th>
                                    {filter === 'closed' ? (
                                        <th style={{ width: '120px' }}>Resolved By</th>
                                    ) : (
                                        <th style={{ width: '120px' }}>Assigned To</th>
                                    )}
                                    <th style={{ textAlign: 'center', width: '120px' }}>View Ticket</th>
                                    {filter === 'closed' ? (
                                        <th style={{ textAlign: 'center', width: '120px' }}></th>
                                    ) : (
                                        <th style={{ textAlign: 'center', width: '120px' }}>Assign Ticket</th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {currentTickets.map((ticket, index) => (
                                    <tr key={index}>
                                        <td>{ticket.ticketId}</td>
                                        <td>{ticket.ticketStatus}</td>
                                        <td>{formatDate(ticket.openDate)}</td>
                                        <td>{ticket.categoryName}</td>
                                        <td>{ticket.ticketType}</td>
                                        <td>{ticket.assignedToName === 'Unassigned' ? '' : ticket.assignedToName}</td>
                                        <td style={{ textAlign: 'center' }}>
                                            <Button onClick={() => handleViewTicket(ticket.ticketId)}>View</Button>
                                        </td>
                                        <td style={{ textAlign: 'center' }}>
                                            {ticket.ticketStatus === 'OPEN' ?
                                                <Button variant='success' onClick={() => handleAssignTicket(ticket.ticketId)}>Assign</Button> : null
                                            }


                                            {((ticket.ticketStatus === 'REVERTED' && ticket.ticketType === null) || ticket.ticketStatus === 'PROGRESS') ?
                                                <Button variant='secondary' onClick={() => handleAssignTicket(ticket.ticketId)}>Re-Assign</Button> : null
                                            }
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Row>


                    {/* <div className='d-flex justify-content-center'>
                        <ul className="pagination">
                            {Array.from({ length: Math.ceil(filteredTickets().length / ticketsPerPage) }, (_, i) => (
                                <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
                                    <button onClick={() => paginate(i + 1)} className="page-link">{i + 1}</button>
                                </li>
                            ))}
                        </ul>
                    </div> */}

                    <Pagination
                        ticketsPerPage={ticketsPerPage}
                        totalTickets={tickets.length}
                        paginate={paginate}
                        currentPage={currentPage}
                    />


                </>
            )}
            <ControllerAssignTicket showAssignTicket={showAssignTicket} setShowAssignTicket={handleCloseAssignTicket} ticketDetails={selectedTicket} />
            <ViewTicket showViewTicket={showViewTicket} setShowViewTicket={handleCloseViewTicket} ticketDetails={selectedTicket} />


        </>
    )
}