import React from 'react'
import { useUserDetails } from '../../contextapi/userDetails'
import { useNavigate } from 'react-router-dom'
import {Row, Button} from 'react-bootstrap'
import Profile from '../../assets/images/profile.jpg'

export default function TrailProfileCard() {
    const {userDetails} = useUserDetails()
    const navigate = useNavigate()

    const navigateTo = () => {
        if (userDetails.userRoleId === 1){
            navigate('/admin')
        }else if(userDetails.userRoleId === 2){
            navigate('/controller')
        }else if(userDetails.userRoleId === 3){
            navigate('/handler')
        }else if(userDetails.userRoleId === 4){
            navigate('/officer')
        }
    }


    return (
        <>
            <img
                src={Profile}
                style={{ borderRadius: '50%', border: '5px solid #0d6efd', width: "10vw" }}
                alt=""
            />

            <Row className='m-1 mt-4 mb-4'>
                <div className='d-flex justify-content-between p-1'>
                    <p className='mb-0'>{userDetails.userName}</p>
                </div><hr className='m-0' />

                <div className='d-flex justify-content-between p-1'>
                    <p className='mb-0'>{userDetails.emailId}</p>
                </div><hr className='m-0' />

                <div className='d-flex justify-content-between p-1'>
                    <p className='mb-0'>{userDetails.phone}</p>
                </div><hr className='m-0' />
            </Row>

            <Button className='m-2 w-100' onClick={() => navigateTo()}>Back to Dashboard</Button>
        </>
    )
}
