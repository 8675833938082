import React from 'react'
import { useHandlerData } from '../../../contextapi/handlerContext';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from 'recharts';

export default function HandlerTicketCounts() {
    const { ticketCounts } = useHandlerData();

    const data = [
        {
            name: 'Today',
            'Assigned': ticketCounts.dayAssignedCount,
            'Unassigned': ticketCounts.dayUnassignedCount,
            'Generated': ticketCounts.dayGeneratedCount,
            'Closed': ticketCounts.dayClosedCount,
        },
        {
            name: 'This Week',
            'Assigned': ticketCounts.weekAssignedCount,
            'Unassigned': ticketCounts.weekUnassignedCount,
            'Generated': ticketCounts.weekGeneratedCount,
            'Closed': ticketCounts.weekClosedCount,
        },
        {
            name: 'This Month',
            'Assigned': ticketCounts.monthAssignedCount,
            'Unassigned': ticketCounts.monthUnassignedCount,
            'Generated': ticketCounts.monthGeneratedCount,
            'Closed': ticketCounts.monthClosedCount,
        }
    ];

    const colors = [
        { name: 'Generated', fill: '#198754' },
        { name: 'Assigned', fill: '#0d6efd' },
        { name: 'Unassigned', fill: '#343a40' },
        { name: 'Closed', fill: '#dc3545' }
    ];


    return (
        <>
            <h4>Overall Ticket Status</h4><hr />
            <>
            <VerticalProgressBar data={data} colors={colors} barGap={2} barSize={30} />
        </>
        </>
    )
}

const VerticalProgressBar = ({ data, colors, barGap, barSize }) => {
    return (
        <BarChart width={500} height={300} data={data} barGap={barGap} barSize={barSize}>
            {/* <CartesianGrid strokeDasharray="3 3" /> */}
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            {colors.map((color, index) => (
                <Bar key={index} dataKey={color.name} fill={color.fill} barSize={barSize} barGap={barGap} />
            ))}
        </BarChart>
    );
};

