const formatDate = (timeStamp) => {

    // Convert the timestamp to a Date object
    const date = new Date(timeStamp);

    // Extract day, month, and year from the Date object
    const day = date.getDate();
    const month = date.getMonth() + 1; // Month starts from 0, so add 1
    const year = date.getFullYear();

    // Pad day and month with leading zeros if necessary
    const paddedDay = day < 10 ? `0${day}` : day;
    const paddedMonth = month < 10 ? `0${month}` : month;

    // Format the date as "dd/mm/yyyy"
    const formattedDate = `${paddedDay}/${paddedMonth}/${year}`;
    if(year < 1990) return null
    return formattedDate
}

export default formatDate;