import React, { useState, useEffect } from 'react';
import './pagination.css'; // Import the CSS file

const Pagination = ({ ticketsPerPage, totalTickets, paginate, currentPage }) => {
  const [visibleRange, setVisibleRange] = useState([1, 9]);
  const totalPages = Math.ceil(totalTickets / ticketsPerPage);

  useEffect(() => {
    // Adjust visible range to keep the active page in the center
    if (currentPage < visibleRange[0] || currentPage > visibleRange[1]) {
      if (currentPage < visibleRange[0]) {
        const newStart = Math.max(1, currentPage - 2);
        const newEnd = Math.min(totalPages, newStart + 4);
        setVisibleRange([newStart, newEnd]);
      } else if (currentPage > visibleRange[1]) {
        const newEnd = Math.min(totalPages, currentPage + 2);
        const newStart = Math.max(1, newEnd - 4);
        setVisibleRange([newStart, newEnd]);
      }
    }
  }, [currentPage, totalPages, visibleRange]);

  const handlePrev = () => {
    if (currentPage > 1) {
      paginate(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      paginate(currentPage + 1);
    }
  };

  const handlePrevRange = () => {
    if (visibleRange[0] > 1) {
      const newStart = visibleRange[0] - 9;
      const newEnd = visibleRange[1] - 9;
      paginate(currentPage - 9)
      setVisibleRange([newStart, newEnd]);
    }
  };

  const handleNextRange = () => {
    if (visibleRange[1] < totalPages) {
      const newStart = visibleRange[0] + 9;
      const newEnd = visibleRange[1] + 9;
      paginate(currentPage + 9)
      setVisibleRange([newStart, newEnd]);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = visibleRange[0]; i <= visibleRange[1] && i <= totalPages; i++) {
      pageNumbers.push(
        <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
          <a onClick={() => paginate(i)} className="page-link" href="#">
            {i}
          </a>
        </li>
      );
    }
    return pageNumbers;
  };

  return (
    <nav className="pagination-container">
      <ul className="pagination">
        <li className="page-item">
          <a onClick={handlePrevRange} className="page-link" href="#">
            &laquo;
          </a>
        </li>
        <li className="page-item">
          <a onClick={handlePrev} className="page-link" href="#">
            &laquo;  Prev
          </a>
        </li>
        {renderPageNumbers()}
        <li className="page-item">
          <a onClick={handleNext} className="page-link" href="#">
            Next &raquo;
          </a>
        </li>        <li className="page-item">
          <a onClick={handleNextRange} className="page-link" href="#">
            &raquo;
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
