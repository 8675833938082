import React from 'react'
import { Button } from 'react-bootstrap'
import { useHandlerData } from '../../../contextapi/handlerContext';

export default function HandlerTicketCountCard(props) {
    const { tickets } = useHandlerData();

    const reviewingTickets = tickets.filter(ticket => ticket.ticketStatus === 'REVIEWING');
    const reviewingTicketsCount = reviewingTickets.length
    const progressTickets = tickets.filter(ticket => ticket.ticketStatus === 'PROGRESS');
    const progressTicketsCount = progressTickets.length
    const closedTickets = tickets.filter(ticket => ticket.ticketStatus === 'CLOSED');
    const closedTicketsCount = closedTickets.length
    const urgentTickets = tickets.filter(ticket => ticket.ticketPriority === 'URGENT' && ticket.ticketStatus !== 'CLOSED');
    const urgentTicketsCount = urgentTickets.length

    return (
        <>
            {props.type === 'URGENT' ? (
                <Button variant='danger' className='w-100 h-100'>
                    <h1>{urgentTicketsCount}</h1>
                    <p>URGENT TICKETS</p>
                </Button>
            ) : null }
            {props.type === 'PROGRESS' ? (
                <Button variant='primary' className='w-100 h-100'>
                    <h1>{progressTicketsCount}</h1>
                    <p>TICKETS IN PROGRESS</p>
                </Button>
            ) : null }
            {props.type === 'CLOSED' ? (
                <Button variant='success' className='w-100 h-100'>
                    <h1>{closedTicketsCount}</h1>
                    <p>CLOSED TICKETS</p>
                </Button>
            ) : null }
            {props.type === 'REVIEWING' ? (
                <Button variant='secondary' className='w-100 h-100'>
                    <h1>{reviewingTicketsCount}</h1>
                    <p>TICKETS WAITING FOR REVIEW</p>
                </Button>
            ) : null }
        </>
    )
}
