import React, { useState, createContext, useContext } from "react";

const DeveloperDataContext = createContext();

export const DeveloperDataProvider = ({ children }) => {
  const [updatedChanges, setUpdatedChanges] = useState([]); // To store information of reverted tickets
  const [tickets, setTickets] = useState([]); // To store ticket details
  const [viewSummary, setViewSummary] = useState(true); // State to switch summarized/complete ticket details
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState('all');



  return (
    <DeveloperDataContext.Provider
      value={{
        updatedChanges,
        setUpdatedChanges,
        tickets,
        setTickets,
        viewSummary,
        setViewSummary,
        searchTerm,
        setSearchTerm,
        filter,
        setFilter
      }}
    >
      {children}
    </DeveloperDataContext.Provider>
  );
};

export const useDeveloperData = () => useContext(DeveloperDataContext);