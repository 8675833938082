import React, { createContext, useContext, useState } from 'react';

const HandlerDataContext = createContext();

export const HandlerDataProvider = ({ children }) => {
  const [updatedChanges, setUpdatedChanges] = useState([]); // To store information of reverted tickets
  const [tickets, setTickets] = useState([]); // To store ticket details
  const [developers, setDevelopers] = useState([]); // To store developers details
  const [complainants, setComplainants] = useState([]); // To store complainant details
  const [ticketCounts, setTicketCounts] = useState([]); // To store complainant details
  const [viewSummary, setViewSummary] = useState(true); // State to switch summarized/complete ticket details
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState('all');



  return (
    <HandlerDataContext.Provider
      value={{
        updatedChanges,
        setUpdatedChanges,
        tickets,
        setTickets,
        ticketCounts,
        setTicketCounts,
        developers,
        setDevelopers,
        complainants,
        setComplainants,
        viewSummary,
        setViewSummary,
        searchTerm,
        setSearchTerm,
        filter,
        setFilter
      }}
    >
      {children}
    </HandlerDataContext.Provider>
  );
};

export const useHandlerData = () => useContext(HandlerDataContext);