// const imageToBlob = (file) => {
//     return new Promise((resolve, reject) => {
//         const reader = new FileReader();
//         reader.onloadend = () => {
//             resolve(new Blob([reader.result], { type: file.type }));
//         };
//         reader.onerror = reject;
//         reader.readAsArrayBuffer(file);
//     });
// };

// const blobToString = (blob) => {
//     return new Promise((resolve, reject) => {
//         const reader = new FileReader();
//         reader.onloadend = () => {
//             const base64String = reader.result.split(',')[1]; // Remove the data URL prefix
//             resolve(base64String);
//         };
//         reader.onerror = reject;
//         reader.readAsDataURL(blob); // Read the Blob as a data URL
//     });
// }

// const imageToString = async (img) => {
//     try {
//         const imgBlob = await imageToBlob(img);
//         const base64String = await blobToString(imgBlob);
//         console.log(base64String)
//         return base64String;
//     } catch (error) {
//         alert('Failed to create string from image');
//         return null;
//     }
// }

// export default imageToString;


// METHOD 2

const imageToBlob = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            resolve(new Blob([reader.result], { type: file.type }));
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
    });
};

const blobToString = (blob) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            const base64String = reader.result.split(',')[1]; // Remove the data URL prefix
            resolve(base64String);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob); // Read the Blob as a data URL
    });
};

const imageToString = async (file) => {
    try {
        const blob = await imageToBlob(file);
        const base64String = await blobToString(blob);
        return base64String;
    } catch (error) {
        console.error('Failed to convert image to string:', error);
        return null;
    }
};

export default imageToString;